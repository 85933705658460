

import "./product.css";
import { products } from "../../data";
import { Link } from "react-router-dom";

const Product = ({img,link,name}) => {
    return (
        <div className="pcontainer">
            <div className="pCover">
            <a href={link} target="_blank" rel="noreferrer" style={{textDecoration:"none"}}>
                         
                <h1 className="pTextCover" style={{ color:"gray"}}>{name}</h1>
            </a>    
               

            
            <a href={link} target="_blank" rel="noreferrer">
                
            
                <img src={img} alt="" className="p-img" />       
            </a>
            </div>
        
            
            
            
        </div>

        
    );
};

export default Product;