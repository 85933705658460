


import "./start.css";
import Me from "../../pics/me.jpg"

const start = () => {
    return (
        <div className="i">
        <div className="i-left">
            <div className="i-left-wrapper">
            <h2 className="i-intro">Hello, My name is</h2>
            <h1 className="i-name">Richard Gubangxa</h1>
            <div className="i-title">
                <div className="i-title-wrapper">
                <div className="i-title-item">Web Developer</div>
                <div className="i-title-item">HTML</div>
                <div className="i-title-item">CSS</div>
                <div className="i-title-item">Javascript</div>

                </div>
            </div>
            <p className="i-desc">
                Up and coming web developer that's proficient in html, css and javascript.
            </p>
            </div>
            
        </div>
        < div className="i-right">
        
            < img src={Me} alt="" className="i-img" />
        </div>
        </div>
    ) ;
};

export default start;