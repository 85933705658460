import "./about.css";
import AboutMe from "../../pics/AboutMe.jpg";


const About = () => {
    return (
        <div className="a">
        <div className="a-left">

            <div className="a-card">
            <img
                src={AboutMe}
                alt=""
                className="a-img"
            />
            </div>
        </div>
        <div className="a-right">
            <h1 className="a-title">About Me</h1>
            <p className="a-sub">
            I love coding!!
            </p>
            < p className="a-desc">
            Recently completed my Conputer Science degree while working a full time job as a waiter.
            This improved my ability to communicate and work with other individuals. Coding gives me the ability
            to make an impact in any field , that's the reason I love coding !
            </p>
            
            </div>
        </div>
    
    );
};

export default About;