import Start from "./components/start/start"
import About from "./components/about/About";
import ProductList from "./components/productList/ProductLIst";
import Contact from "./components/contact/Contact";


function App() {
  return (
    <div>
      <Start />
      <About />
      <ProductList />
      <Contact />

      
    </div>
  );
}

export default App;
