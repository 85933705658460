



import "./contact.css";
import Phone from "../../pics/phone.png"
import Email from "../../pics/email.png";
import Address from "../../pics/address.png";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";


const Contact = () => {
    const formRef = useRef();
    const [done, setDone] = useState(false)



    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs
        .sendForm('service_xu63wor', 'template_jcp85yi', formRef.current, {
            publicKey: 'cijrhzZ28daQJ5Pv9',
        })
        .then(
            () => {
            console.log('SUCCESS!');
            },
            (error) => {
            console.log('FAILED...', error.text);
            },
        );

    };

    return (
        <div className="c">

        <div className="c-wrapper">
            <div className="c-left">
            <h1 className="c-title">Let's discuss your project</h1>
            <div className="c-info">
                <div className="c-info-item">
                < img src={Phone} alt="" className="c-icon" />
                0639418538
                </div>
                <div className="c-info-item">
                <img className="c-icon" src={Email} alt="" />
                richardgubangxa57@gmail.com
                </div>
                
            </div>
            </div>
            <div className="c-right">
            <h2>Get in touch</h2>
            <form ref={formRef} onSubmit={handleSubmit}>
                <input  type="text" placeholder="Name" name="user_name" />
                <input  type="text" placeholder="Subject" name="user_subject" />
                <input  type="text" placeholder="Email" name="user_email" />
                <textarea  rows="5" placeholder="Message" name="message" />
                <button>Submit</button>
                {done && "Thank you..."}
            </form>
            </div>
        </div>
        </div>
    );
};

export default Contact;