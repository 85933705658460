import me from "./pics/commercepicture.jpg";

export const products = [
    {
        id: 1,
        img: me,
        link: "http://commercethesmilingdev.online",
        name: "Commerce shop",
        
    },

];